const dsClasses = {
  ALIGN_ITEMS_BASELINE: 'ds-align-items--baseline',
  ALIGN_ITEMS_CENTER: 'ds-align-items--center',
  ALIGN_ITEMS_FLEX_END: 'ds-align-items--flex-end',
  ALIGN_ITEMS_FLEX_START: 'ds-align-items--flex-start',
  BACKGROUND_COLOR_BLUE_800: 'ds-background-color--blue-800',
  BACKGROUND_COLOR_GREEN_100: 'ds-background-color--green-100',
  BACKGROUND_COLOR_NEUTRAL_200: 'ds-background-color--neutral-200',
  BACKGROUND_COLOR_NEUTRAL_200_LIGHT: 'ds-background-color--neutral-200--light',
  BACKGROUND_COLOR_SECONDARY_BLUE_100: 'ds-background-color--secondary-blue-100',
  BACKGROUND_COLOR_WHITE: 'ds-background-color--white',
  BADGE_TYPE_OUT_OF_STOCK: 'ds-badge--type-out_of_stock',
  BADGE_TYPE_SALE: 'ds-badge--type-sale',
  BADGE_TYPE_SPECIAL: 'ds-badge--type-special',
  BASE_FONT_FAMILY: 'ds-base-font-family',
  BORDER_BOTTOM_WIDTH_1: 'ds-border--bottom-width-1',
  BORDER_STYLE_NONE: 'ds-border--style-none',
  BORDER_STYLE_SOLID: 'ds-border--style-solid',
  BORDER_TOP_WIDTH_0: 'ds-border--top-width-0',
  BORDER_WIDTH_1: 'ds-border--width-1',
  BUTTON: 'ds-button',
  BUTTON_COLOR_DISABLED: 'ds-button--color-disabled',
  BUTTON_COLOR_LIGHT: 'ds-button--color-light',
  BUTTON_COLOR_LINK: 'ds-button--color-link',
  BUTTON_COLOR_PRIMARY: 'ds-button--color-primary',
  BUTTON_CONTENT_STARTS_WITH_ICON: 'ds-button__content--starts_with_icon',
  BUTTON_ICON: 'ds-button-icon',
  BUTTON_SIZE_M: 'ds-button--size-m',
  CARD_PRODUCT_IMAGE: 'ds-card-product-image',
  CARD_PRODUCT_PRICE: 'ds-card-product-price',
  CART_CONTENT_CHILDPRODUCT: 'ds-cart-content-childproduct',
  CART_CONTENT_PRODUCT: 'ds-cart-content-product',
  CART_PRODUCT_DATA: 'ds-cart-product-data',
  CART_PRODUCT_NAME: 'ds-cart-product-name',
  CART_PRODUCT_QUANTITY: 'ds-cart-product-quantity',
  CART_PRODUCT_RECURRINGPRICE: 'ds-cart-product-recurringprice',
  CART_PRODUCT_SINGLEPRICE: 'ds-cart-product-singleprice',
  CHECKBOX: 'ds-checkbox',
  CHECKBOX_DISABLED: 'ds-checkbox--disabled',
  CHECKBOX_HIGHLIGHT: 'ds-checkbox--highlight',
  CHECKBOX_ICON: 'ds-checkbox__icon',
  CHECKBOX_LABEL: 'ds-checkbox__label',
  COLOR_BLACK: 'ds-color--black',
  COLOR_BLUE: 'ds-color--blue',
  COLOR_BLUE_400: 'ds-color--blue-400',
  COLOR_BLUE_600: 'ds-color--blue-600',
  COLOR_BLUE_800: 'ds-color--blue-800',
  COLOR_BRAND_BLUE: 'ds-color--brand-blue',
  COLOR_ERROR: 'ds-color--error',
  COLOR_NEU: 'ds-color--neu',
  COLOR_NEUTRAL_400: 'ds-color--neutral-400',
  COLOR_NEUTRAL_500: 'ds-color--neutral-500',
  COLOR_NEUTRAL_600: 'ds-color--neutral-600',
  COLOR_NEUTRAL_600_LIGHT: 'ds-color--neutral-600--light',
  COLOR_ORANGE: 'ds-color--orange',
  COLOR_ORANGE_600: 'ds-color--orange-600',
  COLOR_RED_600: 'ds-color--red-600',
  DESCRIPTION: 'ds-description',
  DESCRIPTION_LIST_ITEM_DATA: 'ds-description__list-item-data',
  DESCRIPTION_LIST_ITEM_TITLE: 'ds-description__list-item-title',
  DESCRIPTION_TITLE: 'ds-description__title',
  DISABLEDBUTTON: 'ds-disabledbutton',
  DISPLAY_BLOCK: 'ds-display--block',
  DISPLAY_FLEX: 'ds-display--flex',
  DISPLAY_INLINE: 'ds-display--inline',
  DISPLAY_INLINE_BLOCK: 'ds-display--inline-block',
  DISPLAY_INLINE_FLEX: 'ds-display--inline-flex',
  DISPLAY_NONE: 'ds-display--none',
  DISPLAY_UNSET: 'ds-display--unset',
  DS_MARGIN_TOP_0: 'ds-ds-margin-top--0',
  FLOAT_RIGHT: 'ds-float--right',
  FONT_SIZE_LARGE: 'ds-font-size--large',
  FONT_SIZE_MEDIUM: 'ds-font-size--medium',
  FONT_SIZE_SMALL: 'ds-font-size--small',
  FONT_SIZE_SMALLER: 'ds-font-size--smaller',
  FONT_STYLE_NORMAL: 'ds-font-style--normal',
  FONT_WEIGHT_100: 'ds-font-weight--100',
  FONT_WEIGHT_300: 'ds-font-weight--300',
  FONT_WEIGHT_400: 'ds-font-weight--400',
  FONT_WEIGHT_600: 'ds-font-weight--600',
  FONT_WEIGHT_700: 'ds-font-weight--700',
  FONT_WEIGHT_BOLD: 'ds-font-weight--bold',
  FONT_WEIGHT_INITIAL: 'ds-font-weight--initial',
  FONT_WEIGHT_NORMAL: 'ds-font-weight--normal',
  GRID: 'ds-grid',
  GRID_COL: 'ds-grid__col',
  GRID_COL_12: 'ds-grid__col--12',
  GRID_COL_L_3: 'ds-grid__col--l-3',
  GRID_COL_L_6: 'ds-grid__col--l-6',
  GRID_COL_L_9: 'ds-grid__col--l-9',
  GRID_COL_S_6: 'ds-grid__col--s-6',
  GRID_COL_XS_4: 'ds-grid__col--xs-4',
  GRID_ROW: 'ds-grid__row',
  H1: 'ds-h1',
  H2: 'ds-h2',
  H3: 'ds-h3',
  H3_BOOK: 'ds-h3--book',
  H4: 'ds-h4',
  H5: 'ds-h5',
  HEADERSEARCHBUTTON: 'ds-headersearchbutton',
  ICON: 'ds-icon',
  ICON_SIZE_M: 'ds-icon--size-m',
  INPUT: 'ds-input',
  INPUT_DISABLED: 'ds-input-disabled',
  INPUT_ERROR: 'ds-input-error',
  INPUTERROR: 'ds-inputerror',
  INPUTGROUP_INPUTCONTAINER: 'ds-inputgroup__inputcontainer',
  INPUTHELP: 'ds-inputhelp',
  INPUT_INPUTAREA: 'ds-input--inputarea',
  INPUT_INPUTAREA_CLEAR: 'ds-input--inputarea-clear',
  INPUT_INPUTAREA_INPUT: 'ds-input--inputarea-input',
  INPUT_INPUTAREA_TEXTAREA: 'ds-input--inputarea-textarea',
  INPUT_INPUTAREA_VIEWBUTTON: 'ds-input--inputarea-viewbutton',
  INPUT_LABELAREA: 'ds-input--labelarea',
  INPUT_LABELAREA_LABEL: 'ds-input--labelarea-label',
  INPUT_PASSWORD: 'ds-input-password',
  INPUT_READONLY: 'ds-input-readonly',
  INPUT_WITHICON: 'ds-input-withicon',
  JUSTIFY_CONTENT_CENTER: 'ds-justify-content--center',
  JUSTIFY_CONTENT_FLEX_END: 'ds-justify-content--flex-end',
  JUSTIFY_CONTENT_FLEX_START: 'ds-justify-content--flex-start',
  JUSTIFY_CONTENT_SPACE_BETWEEN: 'ds-justify-content--space-between',
  LINK: 'ds-link',
  LINK_BUTTON_COLOR_LIGHT: 'ds-link--button-color-light',
  LINK_BUTTON_COLOR_LIGHT_INVERSE: 'ds-link--button-color-light-inverse',
  LINK_BUTTON_COLOR_LINK: 'ds-link--button-color-link',
  LINK_BUTTON_COLOR_PRIMARY: 'ds-link--button-color-primary',
  LINK_BUTTON_SIZE_L: 'ds-link--button-size-l',
  LINK_BUTTON_SIZE_M: 'ds-link--button-size-m',
  LINK_BUTTON_SIZE_S: 'ds-link--button-size-s',
  LINK_LAST_OBJECT: 'ds-link-last-object',
  LINK_STYLE_LINK_BUTTON: 'ds-link--style-link-button',
  LIST: 'ds-list',
  LIST_ICON: 'ds-list--icon',
  LIST_UNORDERED: 'ds-list--unordered',
  MARGIN_0: 'ds-margin--0',
  MARGIN_1: 'ds-margin--1',
  MARGIN_2: 'ds-margin--2',
  MARGIN_BOTTOM_0: 'ds-margin-bottom--0',
  MARGIN_BOTTOM_1: 'ds-margin-bottom--1',
  MARGIN_BOTTOM_2: 'ds-margin-bottom--2',
  MARGIN_BOTTOM_3: 'ds-margin-bottom--3',
  MARGIN_BOTTOM_4: 'ds-margin-bottom--4',
  MARGIN_BOTTOM_5: 'ds-margin-bottom--5',
  MARGIN_BOTTOM_6: 'ds-margin-bottom--6',
  MARGIN_BOTTOM_7: 'ds-margin-bottom--7',
  MARGIN_BOTTOM_8: 'ds-margin-bottom--8',
  MARGIN_HORIZONTAL_0: 'ds-margin-horizontal--0',
  MARGIN_HORIZONTAL_3: 'ds-margin-horizontal--3',
  MARGIN_HORIZONTAL_4: 'ds-margin-horizontal--4',
  MARGIN_HORIZONTAL_5: 'ds-margin-horizontal--5',
  MARGIN_HORIZONTAL_6: 'ds-margin-horizontal--6',
  MARGIN_HORIZONTAL_7: 'ds-margin-horizontal--7',
  MARGIN_LEFT_0: 'ds-margin-left--0',
  MARGIN_LEFT_1: 'ds-margin-left--1',
  MARGIN_LEFT_2: 'ds-margin-left--2',
  MARGIN_LEFT_3: 'ds-margin-left--3',
  MARGIN_LEFT_4: 'ds-margin-left--4',
  MARGIN_LEFT_5: 'ds-margin-left--5',
  MARGIN_RIGHT_0: 'ds-margin-right--0',
  MARGIN_RIGHT_1: 'ds-margin-right--1',
  MARGIN_RIGHT_2: 'ds-margin-right--2',
  MARGIN_RIGHT_3: 'ds-margin-right--3',
  MARGIN_RIGHT_4: 'ds-margin-right--4',
  MARGIN_RIGHT_5: 'ds-margin-right--5',
  MARGIN_RIGHT_8: 'ds-margin-right--8',
  MARGIN_TOP_0: 'ds-margin-top--0',
  MARGIN_TOP_1: 'ds-margin-top--1',
  MARGIN_TOP_10: 'ds-margin-top--10',
  MARGIN_TOP_2: 'ds-margin-top--2',
  MARGIN_TOP_3: 'ds-margin-top--3',
  MARGIN_TOP_4: 'ds-margin-top--4',
  MARGIN_TOP_5: 'ds-margin-top--5',
  MARGIN_TOP_6: 'ds-margin-top--6',
  MARGIN_TOP_7: 'ds-margin-top--7',
  MARGIN_TOP_8: 'ds-margin-top--8',
  MARGIN_TOP_9: 'ds-margin-top--9',
  MARGIN_VERTICAL_0: 'ds-margin-vertical--0',
  MARGIN_VERTICAL_2: 'ds-margin-vertical--2',
  MARGIN_VERTICAL_3: 'ds-margin-vertical--3',
  MARGIN_VERTICAL_4: 'ds-margin-vertical--4',
  MARGIN_VERTICAL_5: 'ds-margin-vertical--5',
  MARGIN_VERTICAL_6: 'ds-margin-vertical--6',
  MARGIN_VERTICAL_7: 'ds-margin-vertical--7',
  MARGIN_VERTICAL_8: 'ds-margin-vertical--8',
  MODAL: 'ds-modal',
  NAVIGATIONBUTTON: 'ds-navigationbutton',
  NAVIGATIONBUTTON_ICON: 'ds-navigationbutton__icon',
  NEXTPREVBUTTON: 'ds-nextprevbutton',
  NO_GUTTER: 'ds-no-gutter',
  PADDING_0: 'ds-padding--0',
  PADDING_2: 'ds-padding--2',
  PADDING_3: 'ds-padding--3',
  PADDING_4: 'ds-padding--4',
  PADDING_5: 'ds-padding--5',
  PADDING_6: 'ds-padding--6',
  PADDING_8: 'ds-padding--8',
  PADDING_BOTTOM_0: 'ds-padding-bottom--0',
  PADDING_BOTTOM_1: 'ds-padding-bottom--1',
  PADDING_BOTTOM_2: 'ds-padding-bottom--2',
  PADDING_BOTTOM_3: 'ds-padding-bottom--3',
  PADDING_BOTTOM_4: 'ds-padding-bottom--4',
  PADDING_BOTTOM_5: 'ds-padding-bottom--5',
  PADDING_BOTTOM_6: 'ds-padding-bottom--6',
  PADDING_BOTTOM_7: 'ds-padding-bottom--7',
  PADDING_BOTTOM_8: 'ds-padding-bottom--8',
  PADDING_HORIZONTAL_0: 'ds-padding-horizontal--0',
  PADDING_HORIZONTAL_1: 'ds-padding-horizontal--1',
  PADDING_HORIZONTAL_3: 'ds-padding-horizontal--3',
  PADDING_HORIZONTAL_4: 'ds-padding-horizontal--4',
  PADDING_HORIZONTAL_5: 'ds-padding-horizontal--5',
  PADDING_LEFT_0: 'ds-padding-left--0',
  PADDING_LEFT_1: 'ds-padding-left--1',
  PADDING_LEFT_2: 'ds-padding-left--2',
  PADDING_LEFT_3: 'ds-padding-left--3',
  PADDING_LEFT_4: 'ds-padding-left--4',
  PADDING_LEFT_5: 'ds-padding-left--5',
  PADDING_LEFT_6: 'ds-padding-left--6',
  PADDING_LEFT_7: 'ds-padding-left--7',
  PADDING_RIGHT_1: 'ds-padding-right--1',
  PADDING_RIGHT_2: 'ds-padding-right--2',
  PADDING_RIGHT_3: 'ds-padding-right--3',
  PADDING_RIGHT_4: 'ds-padding-right--4',
  PADDING_RIGHT_5: 'ds-padding-right--5',
  PADDING_TOP_1: 'ds-padding-top--1',
  PADDING_TOP_10: 'ds-padding-top--10',
  PADDING_TOP_2: 'ds-padding-top--2',
  PADDING_TOP_3: 'ds-padding-top--3',
  PADDING_TOP_4: 'ds-padding-top--4',
  PADDING_TOP_5: 'ds-padding-top--5',
  PADDING_TOP_6: 'ds-padding-top--6',
  PADDING_TOP_7: 'ds-padding-top--7',
  PADDING_TOP_8: 'ds-padding-top--8',
  PADDING_VERTICAL_0: 'ds-padding-vertical--0',
  PADDING_VERTICAL_2: 'ds-padding-vertical--2',
  PADDING_VERTICAL_3: 'ds-padding-vertical--3',
  PADDING_VERTICAL_4: 'ds-padding-vertical--4',
  PADDING_VERTICAL_5: 'ds-padding-vertical--5',
  PADDING_VERTICAL_7: 'ds-padding-vertical--7',
  PAGINATION_DROPDOWN: 'ds-pagination__dropdown',
  PAGINATIONNAV: 'ds-paginationnav',
  POSITION_ABSOLUTE: 'ds-position--absolute',
  POSITION_RELATIVE: 'ds-position--relative',
  PRICE: 'ds-price',
  PRICE_ADDITIONAL: 'ds-price-additional',
  PRICE_CONTENT: 'ds-price-content',
  PRICE_CONTENT_NUMBER: 'ds-price-content-number',
  QUANTITY_DISABLED: 'ds-quantity-disabled',
  RESET: 'ds-reset',
  SELECTGROUP: 'ds-selectgroup',
  SHOPPINGCART_ADDONS: 'ds-shoppingcart__addons',
  SHOPPINGCART_PRICE_AMOUNT: 'ds-shoppingcart__price-amount',
  SHOPPINGCART_PRICE_UNIT: 'ds-shoppingcart__price-unit',
  SHOPPINGCART_PRODUCT: 'ds-shoppingcart__product',
  SHOPPINGCART_PRODUCT_ADDON: 'ds-shoppingcart__product-addon',
  SHOPPINGCART_PRODUCT_DESCRIPTION: 'ds-shoppingcart__product-description',
  SHOPPINGCART_PRODUCT_DETAILS: 'ds-shoppingcart__product-details',
  SHOPPINGCART_PRODUCT_DISCLAIMER: 'ds-shoppingcart__product-disclaimer',
  SHOPPINGCART_PRODUCT_IMAGE: 'ds-shoppingcart__product-image',
  SHOPPINGCART_PRODUCT_INFO: 'ds-shoppingcart__product-info',
  SHOPPINGCART_PRODUCT_NAME: 'ds-shoppingcart__product-name',
  SHOPPINGCART_PRODUCT_NAME_AND_DESCRIPTION: 'ds-shoppingcart__product-name-and-description',
  SHOPPINGCART_PRODUCT_PRICE: 'ds-shoppingcart__product-price',
  SHOPPINGCART_PRODUCT_PRICE_AND_DISCLAIMER: 'ds-shoppingcart__product-price-and-disclaimer',
  SHOPPINGCART_PRODUCT_PRICE_OPTIONS: 'ds-shoppingcart__product-price-options',
  SHOPPINGCART_PRODUCT_QUANTITY: 'ds-shoppingcart__product-quantity',
  SHOPPINGCART_PRODUCTS: 'ds-shoppingcart__products',
  SHOW_S_AND_UP: 'ds-show-s-and-up',
  SR_ONLY: 'ds-sr-only',
  TABLE: 'ds-table',
  TABLE_BORDERED: 'ds-table--bordered',
  TABLE_CELL_ALIGN_RIGHT: 'ds-table__cell--align-right',
  TABLE_CONTENT_VALIGN_TOP: 'ds-table-content--valign-top',
  TABLE_ROW_IS_CLICKABLE: 'ds-table__row--is-clickable',
  TEXT_ALIGN_CENTER: 'ds-text-align--center',
  TEXT_ALIGN_END: 'ds-text-align--end',
  TEXT_ALIGN_LEFT: 'ds-text-align--left',
  TEXT_ALIGN_RIGHT: 'ds-text-align--right',
  TEXT_BOLD: 'ds-text--bold',
  TEXT_COLOR_ERROR: 'ds-text-color--error',
  TEXT_EXTRA_SMALL: 'ds-text--extra-small',
  TEXT_L: 'ds-text--l',
  TEXT_LARGE: 'ds-text--large',
  TEXT_LEAD: 'ds-text--lead',
  TEXT_M: 'ds-text--m',
  TEXT_NOWRAP: 'ds-text-nowrap',
  TEXT_S: 'ds-text--s',
  TEXT_SMALL: 'ds-text--small',
  TEXT_TRANSFORM_UPPERCASE: 'ds-text-transform--uppercase',
  TEXT_XS: 'ds-text--xs',
  TOOLTIP_BACKGROUND_COLOR: 'ds-tooltip--background-color',
  WHITE_SPACE_NOWRAP: 'ds-white-space--nowrap',
};

export const dsClass = Object.freeze(dsClasses);
