import { AnonymousFixedBroadbandOrderPath } from './path/FixedBroadbandOrder/AnonymousFixedBroadbandOrderPath.js';
import { AnonymousMobileBroadbandPath } from './path/AnonymousMobileBroadbandPath.js';
import { AnonymousMobileVoicePath } from './path/AnonymousMobileVoicePath.js';
import { AuthErrorBoundary, RootErrorBoundary } from './siteUtils.js';
import { AuthenticationReturnPath } from './path/ExternalAuthentication/AuthenticationReturnPath.js';
import { AuthorizeNumberPortingPath } from './path/ExternalAuthentication/AuthorizeNumberPortingPath.js';
import { AuthorizedNumberPortingPath } from './path/ExternalAuthentication/AuthorizedNumberPortingPath.js';
import { AuxiliaryEsimOrderPath } from './path/AuxiliaryEsimOrderPath.js';
import { BillingAccountCreateNewPath } from './path/SelfService/Billing/BillingAccountCreateNewPath.js';
import { BillingAccountLayout } from './path/SelfService/Billing/BillingAccountLayout';
import { BillingAccountPath } from './path/SelfService/Billing/BillingAccountPath.js';
import { BillingAccountSubscriptionsPath } from './path/SelfService/Billing/BillingAccountSubscriptionsPath';
import { BillingAccountsPath } from './path/SelfService/Billing/BillingAccountsPath.js';
import { BroadbandSubActivateSimPath } from './path/SelfService/ProductsAndServices/BroadbandSubActivateSimPath.js';
import { BroadbandSubAddOnPath } from './path/SelfService/ProductsAndServices/BroadbandSubAddOnPath';
import { BroadbandSubNewM2MPath } from './path/SelfService/ProductsAndServices/BroadbandSubNewM2MPath.js';
import { BroadbandSubNewPath } from './path/SelfService/ProductsAndServices/BroadbandSubNewPath.js';
import { BroadbandSubOrderSimPath } from './path/SelfService/ProductsAndServices/BroadbandSubOrderSimPath.js';
import { BroadbandSubUpdateM2MPath } from './path/SelfService/ProductsAndServices/BroadbandSubUpdateM2MPath.js';
import { BroadbandSubUpdatePath } from './path/SelfService/ProductsAndServices/BroadbandSubUpdatePath.js';
import { BroadbandSubsPath } from './path/SelfService/ProductsAndServices/BroadbandSubsPath.js';
import { ChangeRequestPath } from './path/Employee/Orders/ChangeRequestPath.js';
import { ChangeRequestsPath } from './path/Employee/Orders/ChangeRequestsPath.js';
import { Checkout } from '../../components/Checkout/Checkout.js';
import { CheckoutSteps } from '../../components/Checkout/CheckoutSteps.js';
import { CheckoutThankYouOrError } from '../../components/Checkout/CheckoutThankyouOrError.js';
import { CompanyInfoCatalogListEditPath } from './path/SelfService/CompanyInfo/CompanyInfoCatalogListEditPath';
import { CompanyInfoCatalogListInfoPath } from './path/SelfService/CompanyInfo/CompanyInfoCatalogListInfoPath.js';
import { CompanyInfoCatalogListNewPath } from './path/SelfService/CompanyInfo/CompanyInfoCatalogListNewPath.js';
import { CompanyInfoCatalogListProductsPath } from './path/SelfService/CompanyInfo/CompanyInfoCatalogListProductsPath';
import { CompanyInfoCatalogPath } from './path/SelfService/CompanyInfo/CompanyInfoCatalogPath.js';
import {
  CompanyInfoCatalogsOutlet,
  CompanyInfoCatalogsPath,
} from './path/SelfService/CompanyInfo/CompanyInfoCatalogsPath.js';
import { CompanyInfoContactPath } from './path/SelfService/CompanyInfo/CompanyInfoContactPath.js';
import { CompanyInfoContactsPath } from './path/SelfService/CompanyInfo/CompanyInfoContactsPath.js';
import { CompanyInfoHomePath } from './path/SelfService/CompanyInfo/CompanyInfoHomePath.js';
import { CompanyInfoInvitesPath } from './path/SelfService/CompanyInfo/CompanyInfoInvites.js';
import { CompanyInfoMoveContactPath } from './path/SelfService/CompanyInfo/CompanyInfoMoveContactPath';
import { CompanyInfoMyAccountPath } from './path/SelfService/CompanyInfo/CompanyInfoMyAccountPath.js';
import { CompanyInfoNewContactPath } from './path/SelfService/CompanyInfo/CompanyInfoNewContactPath.js';
import { CompanyInfoReportsPath } from './path/SelfService/CompanyInfo/CompanyInfoReportsPath.js';
import { CompanyInfoSettingsPath } from './path/SelfService/CompanyInfo/CompanyInfoSettingsPath';
import { ContactFormPage } from '../../components/ContactFormPage/ContactFormPage.js';
import { ContractsPath } from './path/SelfService/ProductsAndServices/ContractsPath.js';
import { CustomerOrderPath } from './path/SelfService/OrderHistory/CustomerOrderPath.js';
import { CustomerOrderProductPath } from './path/SelfService/OrderHistory/CustomerOrderProductPath.js';
import { CustomerOrderRedeemRequestPath } from './path/SelfService/OrderHistory/CustomerOrderRedeemRequestPath.js';
import { CustomerOrderRedeemRequestsPath } from './path/SelfService/OrderHistory/CustomerOrderRedeemRequestsPath.js';
import { CustomerOrdersPath } from './path/SelfService/OrderHistory/CustomerOrdersPath.js';
import { DeviceAddOnPath } from './path/SelfService/ProductsAndServices/DeviceAddOnPath';
import { DeviceAddPath } from './path/Employee/DevicesAndSubscriptions/special/DeviceAddPath.js';
import { DeviceCheckoutCardPayment } from '../../components/DeviceCheckoutCardPayment/DeviceCheckoutCardPayment.js';
import { DeviceRedemptionPath } from './path/SelfService/ProductsAndServices/DeviceRedemptionPath.js';
import { DnsManagementPath } from './path/SelfService/DnsManagement/DnsManagementPath.js';
import { DnsRecordHistoryPath } from './path/SelfService/DnsManagement/DnsRecordHistoryPath.js';
import { DnsRecordsHistoryPath } from './path/SelfService/DnsManagement/DnsRecordsHistoryPath.js';
import { DnsRecordsPath } from './path/SelfService/DnsManagement/DnsRecordsPath.js';
import { EmailVerificationPath } from './path/Employee/EmailVerificationPath.js';
import { Employee } from '../../components/Employee/Employee.js';
import { EmployeeHomePath } from './path/Employee/EmployeeHomePath.js';
import { EmployeeOneTimePasswordPath } from '../../components/EmployeeSubscriptionPairingScene/EmployeeOneTimePasswordPath.js';
import { EmployeeOwnInfoPath } from './path/Employee/EmployeeOwnInfoPath.js';
import { EmployeeStore } from './path/Employee/DeviceStore/EmployeeStore.js';
import { EmployeeStoreProduct } from './path/Employee/DeviceStore/EmployeeStoreProduct.js';
import { EmployeeStoreProducts } from './path/Employee/DeviceStore/EmployeeStoreProducts.js';
import { BroadbandSubPath as EoeBroadbandSubPath } from './path/Employee/DevicesAndSubscriptions/singular/BroadbandSubPath.js';
import { DevicePath as EoeDevicePath } from './path/Employee/DevicesAndSubscriptions/singular/DevicePath.js';
import { DeviceSupportRequestPath as EoeDeviceSupportRequestPath } from './path/Employee/DevicesAndSubscriptions/special/DeviceSupportRequestPath.js';
import { DevicesPath as EoeDevicesPath } from './path/Employee/DevicesAndSubscriptions/plural/DevicesPath.js';
import {
  ExtAuthLayout,
  OmaElisaNavigationLayout,
  OmaElisaProtectedRoutesLayout,
  PrivateAuthenticationLayout,
  PublicAuthenticationLayout,
  PublicHeaderCmsLayout,
  PublicHeaderLayout,
  PublicPathLayout,
  PunchoutNavigationLayout,
  ShoppingBasketLayout,
} from './SiteLayouts.js';
import { FinishedReviewItemPath } from './path/Employee/Orders/FinishedReviewItemPath.js';
import { FinishedReviewPath } from './path/Employee/Orders/FinishedReviewPath.js';
import { FinishedReviewsPath } from './path/Employee/Orders/FinishedReviewsPath.js';
import { FixedBroadbandAvailabilityPath } from './path/FixedBroadbandOrder/FixedBroadbandAvailabilityPath.js';
import { FixedBroadbandCheckoutPath } from './path/FixedBroadbandOrder/FixedBroadbandCheckoutPath.js';
import { FixedBroadbandOffersPath } from './path/FixedBroadbandOrder/FixedBroadbandOffersPath.js';
import { FixedBroadbandThankyouPath } from './path/FixedBroadbandOrder/FixedBroadbandThankyouPath.js';
import { ForgotPasswordPath } from './path/ForgotPasswordPath.js';
import { InstructionsPath } from './path/Public/InstructionsPath.js';
import { InvoiceDocumentsPath } from './path/SelfService/Billing/InvoiceDocumentsPath.js';
import { InvoicePath } from './path/SelfService/Billing/InvoicePath.js';
import { InvoicesPath } from './path/SelfService/Billing/InvoicesPath.js';
import { LicenseManagementPath } from './path/SelfService/ProductsAndServices/LicenseManagementPath.js';
import { MfaSelfService } from '../../components/MFA/MfaSelfService';
import { MobileIdDetailsPath } from './path/SelfService/ProductsAndServices/MobileIdDetailsPath.js';
import { MobileIdNewPath } from './path/SelfService/ProductsAndServices/MobileIdNewPath.js';
import { MobileSubActivateSimPath } from './path/SelfService/ProductsAndServices/MobileSubActivateSimPath.js';
import { MobileSubAddOnPath } from './path/SelfService/ProductsAndServices/MobileSubAddOnPath';
import { MobileSubAttachRingPath } from './path/SelfService/ProductsAndServices/MobileSubAttachRingPath.js';
import { MobileSubAttachVakioPath } from './path/SelfService/ProductsAndServices/MobileSubAttachVakioPath.js';
import { MobileSubNewPath } from './path/SelfService/ProductsAndServices/MobileSubNewPath.js';
import { MobileSubOrderSimPath } from './path/SelfService/ProductsAndServices/MobileSubOrderSimPath.js';
import { MobileSubPath } from './path/SelfService/ProductsAndServices/MobileSubPath.js';
import { MobileSubUpdateConfirmationPath } from './path/SelfService/ProductsAndServices/MobileSubUpdateConfirmationPath.js';
import { MobileSubUpdatePath } from './path/SelfService/ProductsAndServices/MobileSubUpdatePath.js';
import { MobileSubsPath } from './path/SelfService/ProductsAndServices/MobileSubsPath.js';
import { ModelType } from '../../common/enums.js';
import { Navigate, Route, createRoutesFromElements } from 'react-router-dom';
import { NewSupportCaseAuthenticatedPath } from './path/NewSupportCaseAuthenticatedPath.js';
import { NewSupportCasePublic } from '../../components/NewSupportCasePublic/NewSupportCasePublic';
import { NewSupportCasePublicThankYou } from '../../components/NewSupportCasePublicThankYou/NewSupportCasePublicThankYou';
import { BroadbandSubPath as NoeBroadbandSubPath } from './path/SelfService/ProductsAndServices/BroadbandSubPath.js';
import { DevicePath as NoeDevicePath } from './path/SelfService/ProductsAndServices/DevicePath.js';
import { DeviceSupportRequestPath as NoeDeviceSupportRequestPath } from './path/SelfService/ProductsAndServices/DeviceSupportRequestPath.js';
import { DevicesPath as NoeDevicesPath } from './path/SelfService/ProductsAndServices/DevicesPath.js';
import { Omalasku } from '../../components/Omalasku/Omalasku';
import { OmalaskuOrderBarrings } from '../../components/Omalasku/OmalaskuOrderBarrings';
import { OmalaskuSelfService } from '../../components/Omalasku/OmalaskuSelfService';
import { OpenFormLayout } from '../../components/OpenForm/OpenFormLayout/OpenFormLayout.js';
import { OpenFormListLayout } from '../../components/OpenForm/OpenFormList/OpenFormListLayout.js';
import { OpenFormThankYou } from '../../components/OpenForm/OpenFormThankYou/OpenFormThankYou.js';
import { OpenFormViewLayout } from '../../components/OpenForm/OpenFormView/OpenFormViewLayout.js';
import { OrderBroadbandSubConfigPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderBroadbandSubConfigPath.js';
import { OrderBroadbandSubDeliveryOptionsPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderBroadbandSubDeliveryOptionsPath.js';
import { OrderBroadbandSubSelectionPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderBroadbandSubSelectionPath.js';
import { OrderM2MSubConfigPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderM2MSubConfigPath.js';
import { OrderM2MSubDeliveryOptionsPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderM2MSubDeliveryOptionsPath.js';
import { OrderM2MSubSelectionPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderM2MSubSelectionPath.js';
import { OrderVoiceSubConfigPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderVoiceSubConfigPath.js';
import { OrderVoiceSubDeliveryOptionsPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderVoiceSubDeliveryOptionsPath.js';
import { OrderVoiceSubSelectionPath } from './path/SelfService/ProductsAndServices/OrderSubscription/OrderVoiceSubSelectionPath.js';
import { OwnChangeRequestPath } from './path/Employee/OwnOrders/OwnChangeRequestPath.js';
import { OwnChangeRequestsPath } from './path/Employee/OwnOrders/OwnChangeRequestsPath.js';
import { OwnOrderItemPath } from './path/Employee/OwnOrders/OwnOrderItemPath.js';
import { OwnOrderPath } from './path/Employee/OwnOrders/OwnOrderPath.js';
import { OwnOrdersPath } from './path/Employee/OwnOrders/OwnOrdersPath.js';
import { PendingReviewItemPath } from './path/Employee/Orders/PendingReviewItemPath.js';
import { PendingReviewPath } from './path/Employee/Orders/PendingReviewPath.js';
import { PendingReviewsPath } from './path/Employee/Orders/PendingReviewsPath.js';
import { PublicM2MSubscriptions } from '../../components/PublicM2MSubscriptions/PublicM2MSubscriptions';
import { PublicPath } from './path/PublicPath.js';
import { PunchoutCheckoutPath } from './path/Punchout/PunchoutCheckoutPath.js';
import { PunchoutHeader } from '../../components/Punchout/partials/PunchoutHeader.js';
import { PunchoutPath } from './path/Punchout/PunchoutPath.js';
import { PunchoutStorePath } from './path/Punchout/PunchoutStorePath.js';
import { PunchoutStoreProduct } from './path/Punchout/PunchoutStoreProduct.js';
import { PunchoutStoreProducts } from './path/Punchout/PunchoutStoreProducts.js';
import { RegistrationPath } from './path/RegistrationPath.js';
import { SearchPublicPagesPath } from './path/SearchPublicPagesPath.js';
import { SelfServiceHomePath } from './path/SelfService/SelfServiceHomePath.js';
import { ServicePath } from './path/SelfService/ProductsAndServices/ServicePath.js';
import { ServicesPath } from './path/SelfService/ProductsAndServices/ServicesPath.js';
import { ShoppingBasketPath } from './path/ShoppingBasketPath.js';
import { ShoppingCartPath } from './path/ShoppingCartPath.js';
import { SiteFn } from './SiteFn.js';
import { SubAddPath } from './path/Employee/DevicesAndSubscriptions/special/SubAddPath.js';
import { SubsPath } from './path/Employee/DevicesAndSubscriptions/plural/SubsPath.js';
import { SupportCasePath } from '../../components/MessagingCenter/SupportCasePath.js';
import { SupportCasePublicPath } from './path/SupportCasePublicPath.js';
import { SupportCasesPath } from './path/SelfService/MessagingCenter/SupportCasesPath.js';
import { TrainingEnrollmentPath } from './path/TrainingEnrollmentPath.js';
import { VoiceSubActivateSimPath } from './path/Employee/DevicesAndSubscriptions/special/VoiceSubActivateSimPath.js';
import { VoiceSubCallDetailsPath } from './path/Employee/DevicesAndSubscriptions/special/VoiceSubCallDetailsPath.js';
import { VoiceSubOrderSimPath } from './path/Employee/DevicesAndSubscriptions/special/VoiceSubOrderSimPath.js';
import { VoiceSubPath } from './path/Employee/DevicesAndSubscriptions/singular/VoiceSubPath.js';
import {
  addonId,
  billingAccountId,
  caseDisplayId,
  catalogCode,
  checkoutCardPaymentStatus,
  contractDisplayId,
  customerOrderDisplayId,
  deliveryId,
  deviceName,
  formId,
  historyId,
  invoiceId,
  listId,
  msisdn,
  orderId,
  page,
  requestId,
  subscriptionId,
  token,
  trainingName,
} from '../../common/constants/pathInterfaces.js';
import {
  billingAccountLayoutLoader,
  billingAccountListLoader,
  billingAccountLoader,
  billingAccountSubscriptionsLoader,
  broadbandSubAddOnLoader,
  broadbandSubPostChangeRequestLoader,
  broadbandSubUpdateLoader,
  broadbandSubUpdateM2MLoader,
  broadbandSubscriptionLoader,
  broadbandSubscriptionsLoader,
  catalogConfigurationsLoader,
  catalogProductsLoader,
  catalogSummaryLoader,
  chatHistoryLoader,
  checkoutRootLoader,
  companyInfoHomeLoader,
  companyInvitesLoader,
  contactLoader,
  contactsLoader,
  createNewBillingAccountLoader,
  customerOrderLoader,
  customerOrdersLoader,
  deliveryOrderLoader,
  deviceAddOnLoader,
  deviceSubPostChangeRequestLoader,
  deviceSubscriptionLoader,
  deviceSubscriptionsLoader,
  dnsRecordHistoryLoader,
  dnsRecordsHistoryLoader,
  dnsSubscriptionsLoader,
  documentsLoader,
  fixedBBCheckoutLoader,
  fixedBBRootLoader,
  getDnsRecords,
  invoiceLoader,
  invoicesLoader,
  mfaDetailsLoader,
  mobileIdContractLoader,
  mobileIdContractsLoader,
  mobileSubAddOnLoader,
  mobileSubAttachRingLoader,
  mobileSubAttachVakioLoader,
  mobileSubPostChangeRequestLoader,
  mobileSubUpdateConfirmationLoader,
  mobileSubUpdateLoader,
  mobileSubscriptionLoader,
  moveContactLoader,
  newMobileIdContractLoader,
  onlineModelsForBasketItemsLoader,
  orderM2MLoader,
  orderMobileBBSubLoader,
  orderSubscriptionCommonLoader,
  orderSubscriptionConfigLoader,
  orderSubscriptionDeliveryOptionsLoader,
  orderVoiceSubLoader,
  publicM2MBroadbandPageLoader,
  reportsLoader,
  serviceSubscriptionLoader,
  serviceSubscriptionsLoader,
  subscriptionActionLoader,
  subscriptionActionsLoader,
  supportCaseLoader,
  supportCasesLoader,
  virtualCatalogDetailsLoader,
  virtualCatalogsLoader,
  voiceSubscriptionsLoader,
} from '../../common/loaders.js';
import { categories } from '../../common/utils/categoryUtils.js';
import { chatConversationAction } from '../../common/actions.js';
import { fetchPublicPageLoader, fetchTrainings } from '../../common/fetch.js';
import { paths } from '../../common/constants/pathVariables.js';

import '../../common/style/component-library-overrides.scss';
import '../../common/style/global-styles.scss';
import '../../common/style/pattern-library-overrides.scss';
import './SitePath.scss';

// Whenever making changes to this file (that changes the paths/routes in RRD) you need to run `yarn generate-routes`.

const SitePath = () => (
  <Route element={<SiteFn />} errorElement={<RootErrorBoundary />}>
    <Route element={<PrivateAuthenticationLayout />} errorElement={<RootErrorBoundary />}>
      <Route path="/omaelisa/multisim/*" element={<AuxiliaryEsimOrderPath />} />
      <Route path="/omaelisa/palauta-salasana" element={<ForgotPasswordPath />} />
      <Route path="/omaelisa" element={<OmaElisaNavigationLayout />}>
        {/* Home path */}
        <Route index element={<SelfServiceHomePath />} />

        <Route element={<OmaElisaProtectedRoutesLayout />} errorElement={<AuthErrorBoundary />}>
          {/* Company Info paths */}
          <Route path="yritystiedot">
            <Route index element={<CompanyInfoHomePath />} loader={companyInfoHomeLoader} />
            <Route path="asetukset" id="settingsRoot" loader={mfaDetailsLoader}>
              <Route index element={<CompanyInfoSettingsPath />} loader={companyInfoHomeLoader} />
              <Route path="mfa-self-service" element={<MfaSelfService />} />
            </Route>
            <Route path="käyttäjät">
              <Route index element={<CompanyInfoContactsPath />} loader={contactsLoader} />
              <Route path=":contactMasterId">
                <Route index element={<CompanyInfoContactPath />} loader={contactLoader} />
                <Route path="siirrä-käyttäjä" element={<CompanyInfoMoveContactPath />} loader={moveContactLoader} />
              </Route>
              <Route path="lisää-uusi-käyttäjä" element={<CompanyInfoNewContactPath />} />
            </Route>
            <Route
              path="kutsut"
              element={<CompanyInfoInvitesPath />}
              loader={companyInvitesLoader}
              shouldRevalidate={({ currentUrl, nextUrl, defaultShouldRevalidate }) =>
                currentUrl.pathname !== nextUrl.pathname || defaultShouldRevalidate
              }
            />
            <Route path="laitelistat">
              <Route element={<CompanyInfoCatalogsOutlet />}>
                <Route index element={<CompanyInfoCatalogsPath />} loader={virtualCatalogsLoader} />
                <Route
                  path={`:${catalogCode}`}
                  element={<CompanyInfoCatalogPath />}
                  loader={virtualCatalogDetailsLoader}
                />
                <Route
                  path="uusi-laitelista"
                  element={<CompanyInfoCatalogListNewPath />}
                  loader={catalogConfigurationsLoader}
                />
                <Route
                  path="laitelistan-muokkaus"
                  element={<CompanyInfoCatalogListEditPath />}
                  loader={catalogConfigurationsLoader}
                />
                <Route
                  path="laitelistan-tuotteet"
                  element={<CompanyInfoCatalogListProductsPath />}
                  loader={catalogProductsLoader}
                />
                <Route
                  path="laitelistan-tiedot"
                  element={<CompanyInfoCatalogListInfoPath />}
                  loader={catalogSummaryLoader}
                />
              </Route>
            </Route>
            <Route path="raportit" element={<CompanyInfoReportsPath />} loader={reportsLoader} />
            <Route path="omat-tiedot" element={<CompanyInfoMyAccountPath />} />
          </Route>

          {/* Billing paths */}
          <Route path="laskutussopimukset">
            <Route index element={<BillingAccountsPath />} loader={billingAccountListLoader} />
            <Route
              path="luo-uusi-laskutussopimus"
              element={<BillingAccountCreateNewPath />}
              loader={createNewBillingAccountLoader}
            />
            <Route
              path={`:${billingAccountId}`}
              element={<BillingAccountLayout />}
              id="baRoot"
              loader={billingAccountLayoutLoader}
              shouldRevalidate={({ currentUrl, nextUrl }) => currentUrl.pathname !== nextUrl.pathname}
            >
              <Route index element={<BillingAccountPath />} loader={billingAccountLoader} />
              <Route
                path="tuotteet"
                element={<BillingAccountSubscriptionsPath />}
                loader={billingAccountSubscriptionsLoader}
              />
            </Route>
          </Route>
          <Route path="laskut">
            <Route index element={<InvoicesPath />} loader={invoicesLoader} />
            <Route path={`:${invoiceId}`} element={<InvoicePath />} loader={invoiceLoader} />
          </Route>
          <Route path="laskutukseen-liittyvät-kirjeet" element={<InvoiceDocumentsPath />} loader={documentsLoader} />

          {/* Order History paths */}
          <Route path="tilaushistoria">
            <Route index element={<Navigate replace to={paths.CUSTOMER_ORDERS} />} />
            <Route path="tilaukset">
              <Route index element={<CustomerOrdersPath />} loader={customerOrdersLoader} id="orders" />
              <Route path={`:${orderId}`}>
                <Route index element={<CustomerOrderPath />} loader={customerOrderLoader} />
                <Route
                  path={`:${subscriptionId}`}
                  element={<CustomerOrderProductPath />}
                  loader={deliveryOrderLoader}
                />
              </Route>
            </Route>
            <Route path="lunastuspyynnot">
              <Route index element={<CustomerOrderRedeemRequestsPath />} loader={subscriptionActionsLoader} />
              <Route
                path={`:${requestId}`}
                element={<CustomerOrderRedeemRequestPath />}
                loader={subscriptionActionLoader}
              />
            </Route>
          </Route>

          {/* Products and Services paths */}
          <Route path="tuotteeni-ja-palveluni">
            <Route index element={<Navigate replace to={paths.PS_MOBILE_SUBSCRIPTIONS} />} />
            <Route path="puhelinliittymät">
              <Route index element={<MobileSubsPath />} loader={voiceSubscriptionsLoader} />
              <Route
                path="uusi-liittymä"
                element={<MobileSubNewPath />}
                loader={orderSubscriptionCommonLoader}
                id="newVoiceSubCommon"
              >
                <Route index element={<OrderVoiceSubSelectionPath />} loader={orderVoiceSubLoader} />
                <Route
                  path="tuotteiden-tiedot"
                  element={<OrderVoiceSubConfigPath />}
                  loader={_ => orderSubscriptionConfigLoader(_, ModelType.Voice)}
                />
                <Route
                  path="toimitustiedot"
                  element={<OrderVoiceSubDeliveryOptionsPath />}
                  loader={orderSubscriptionDeliveryOptionsLoader}
                />
              </Route>
              <Route path={`:${subscriptionId}`}>
                <Route index element={<MobileSubPath />} loader={mobileSubscriptionLoader} />
                <Route
                  path="aktivoi-sim-kortti"
                  element={<MobileSubActivateSimPath />}
                  loader={mobileSubPostChangeRequestLoader}
                />
                <Route path="liitä-ring" element={<MobileSubAttachRingPath />} loader={mobileSubAttachRingLoader} />
                <Route path="liitä-vakio" element={<MobileSubAttachVakioPath />} loader={mobileSubAttachVakioLoader} />
                <Route path="päivitä-liittymä" element={<MobileSubUpdatePath />} loader={mobileSubUpdateLoader} />
                <Route
                  path="päivitä-liittymä/vahvista"
                  element={<MobileSubUpdateConfirmationPath />}
                  loader={mobileSubUpdateConfirmationLoader}
                />
                <Route path="tilaa-sim" element={<MobileSubOrderSimPath />} loader={mobileSubPostChangeRequestLoader} />
                <Route path={`:${addonId}`} element={<MobileSubAddOnPath />} loader={mobileSubAddOnLoader} />
              </Route>
            </Route>
            <Route path="nettiliittymät">
              <Route index element={<BroadbandSubsPath />} loader={broadbandSubscriptionsLoader} />
              <Route
                path="uusi-mobiililaajakaista"
                element={<BroadbandSubNewPath />}
                loader={orderSubscriptionCommonLoader}
                id="newMobileBBCommon"
              >
                <Route index element={<OrderBroadbandSubSelectionPath />} loader={orderMobileBBSubLoader} />
                <Route
                  path="tuotteiden-tiedot"
                  element={<OrderBroadbandSubConfigPath />}
                  loader={_ => orderSubscriptionConfigLoader(_, ModelType.MobileBroadband)}
                />
                <Route
                  path="toimitustiedot"
                  element={<OrderBroadbandSubDeliveryOptionsPath />}
                  loader={orderSubscriptionDeliveryOptionsLoader}
                />
              </Route>
              <Route
                path="uusi-laitenetti"
                element={<BroadbandSubNewM2MPath />}
                loader={orderSubscriptionCommonLoader}
                id="newM2MCommon"
              >
                <Route index element={<OrderM2MSubSelectionPath />} loader={orderM2MLoader} />
                <Route
                  path="tuotteiden-tiedot"
                  element={<OrderM2MSubConfigPath />}
                  loader={_ => orderSubscriptionConfigLoader(_, ModelType.MobileM2M)}
                />
                <Route
                  path="toimitustiedot"
                  element={<OrderM2MSubDeliveryOptionsPath />}
                  loader={orderSubscriptionDeliveryOptionsLoader}
                />
              </Route>
              <Route path={`:${subscriptionId}`}>
                <Route index element={<NoeBroadbandSubPath />} loader={broadbandSubscriptionLoader} />
                <Route
                  path="aktivoi-sim-kortti"
                  element={<BroadbandSubActivateSimPath />}
                  loader={broadbandSubPostChangeRequestLoader}
                />
                <Route
                  path="tilaa-sim"
                  element={<BroadbandSubOrderSimPath />}
                  loader={broadbandSubPostChangeRequestLoader}
                />
                <Route
                  path="päivitä-mobiililaajakaista"
                  element={<BroadbandSubUpdatePath />}
                  loader={broadbandSubUpdateLoader}
                />
                <Route
                  path="päivitä-mobiililaajakaista/vahvista"
                  element={<MobileSubUpdateConfirmationPath />}
                  loader={mobileSubUpdateConfirmationLoader}
                />
                <Route
                  path="päivitä-laitenetti"
                  element={<BroadbandSubUpdateM2MPath />}
                  loader={broadbandSubUpdateM2MLoader}
                />
                <Route
                  path="päivitä-laitenetti/vahvista"
                  element={<MobileSubUpdateConfirmationPath />}
                  loader={mobileSubUpdateConfirmationLoader}
                />
                <Route path={`:${addonId}`} element={<BroadbandSubAddOnPath />} loader={broadbandSubAddOnLoader} />
              </Route>
            </Route>
            <Route path="laitteet">
              <Route index element={<NoeDevicesPath />} loader={deviceSubscriptionsLoader} />
              <Route path={`:${subscriptionId}`}>
                <Route index element={<NoeDevicePath />} loader={deviceSubscriptionLoader} />
                <Route
                  path="tee-tukipyynto"
                  element={<NoeDeviceSupportRequestPath />}
                  loader={deviceSubPostChangeRequestLoader}
                />
                <Route
                  path="laitteen-lunastaminen"
                  element={<DeviceRedemptionPath />}
                  loader={deviceSubPostChangeRequestLoader}
                  shouldRevalidate={({ currentUrl, nextUrl }) => {
                    return nextUrl.pathname !== currentUrl.pathname;
                  }}
                />
                <Route path={`:${addonId}`} element={<DeviceAddOnPath />} loader={deviceAddOnLoader} />
              </Route>
            </Route>
            <Route path="palvelut">
              <Route index element={<ServicesPath />} loader={serviceSubscriptionsLoader} />
              <Route path={`:${subscriptionId}`}>
                <Route index element={<ServicePath />} loader={serviceSubscriptionLoader} />
              </Route>
            </Route>
            <Route path="lisenssit" element={<LicenseManagementPath />} />
            <Route path="sopimukset">
              <Route index element={<ContractsPath />} loader={mobileIdContractsLoader} />
              <Route path="uusi-mobiilivarmenne" element={<MobileIdNewPath />} loader={newMobileIdContractLoader} />
              <Route path={`:${contractDisplayId}`} element={<MobileIdDetailsPath />} loader={mobileIdContractLoader} />
            </Route>
            <Route path="elisa-dns">
              <Route index element={<DnsManagementPath />} loader={dnsSubscriptionsLoader} />
              <Route path={`:${subscriptionId}`}>
                <Route index element={<DnsRecordsPath />} loader={getDnsRecords} />
                <Route path="muutoshistoria">
                  <Route index element={<DnsRecordsHistoryPath />} loader={dnsRecordsHistoryLoader} />
                  <Route path={`:${historyId}`} element={<DnsRecordHistoryPath />} loader={dnsRecordHistoryLoader} />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Messaging center paths */}
          <Route path="tuki">
            <Route path="tukipyynnöt">
              <Route index element={<SupportCasesPath />} loader={supportCasesLoader} />
              <Route path="luo-uusi" element={<NewSupportCaseAuthenticatedPath />} />
              <Route path={`:${caseDisplayId}`} element={<SupportCasePath />} loader={supportCaseLoader} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="/punchout" element={<PunchoutPath />}>
        <Route index element={<Navigate replace to={paths.PUNCHOUT_STORE} />} />
        <Route path="kassa" element={<PunchoutCheckoutPath />} />
        <Route path="ostoskori" element={<ShoppingCartPath header={<PunchoutHeader />} />} />
        <Route path="kauppa" element={<PunchoutNavigationLayout />}>
          <Route index element={<PunchoutStorePath />} />
          <Route path="puhelimet">
            <Route index element={<PunchoutStoreProducts category={categories.PHONE} />} />
            <Route path={`:${deviceName}`} element={<PunchoutStoreProduct category={categories.PHONE} />} />
          </Route>
          <Route path="tabletit">
            <Route index element={<PunchoutStoreProducts category={categories.TABLET} />} />
            <Route path={`:${deviceName}`} element={<PunchoutStoreProduct category={categories.TABLET} />} />
          </Route>
          <Route path="tietokoneet">
            <Route index element={<PunchoutStoreProducts category={categories.COMPUTER} />} />
            <Route path={`:${deviceName}`} element={<PunchoutStoreProduct category={categories.COMPUTER} />} />
          </Route>
          <Route path="verkkolaitteet">
            <Route index element={<PunchoutStoreProducts category={categories.NETWORK} />} />
            <Route path={`:${deviceName}`} element={<PunchoutStoreProduct category={categories.NETWORK} />} />
          </Route>
          <Route path="lisatarvikkeet">
            <Route index element={<PunchoutStoreProducts category={categories.ACCESSORY} />} />
            <Route path={`:${deviceName}`} element={<PunchoutStoreProduct category={categories.ACCESSORY} />} />
          </Route>
        </Route>
      </Route>
      <Route
        path="/kassa"
        element={<Checkout isEmployee={false} useBasketData={true} />}
        loader={checkoutRootLoader}
        id="checkoutRoot"
      >
        <Route path="kiitos" element={<CheckoutThankYouOrError isEmployee={false} useBasketData={true} />} />
        <Route path={`maksu/:${checkoutCardPaymentStatus}`} element={<DeviceCheckoutCardPayment />} />
        <Route index element={<CheckoutSteps isEmployee={false} rootLoaderId="checkoutRoot" useBasketData={true} />} />
      </Route>
      <Route element={<ShoppingBasketLayout />}>
        <Route
          path="/ostoskori"
          element={<ShoppingBasketPath />}
          loader={onlineModelsForBasketItemsLoader}
          id="shoppingBasketRoot"
        />
      </Route>
      <Route
        path={`/ilmo/:${trainingName}`}
        element={<TrainingEnrollmentPath />}
        loader={args => fetchTrainings(args.params.trainingName!)}
      />
      <Route path="/tunnistaudu" element={<ExtAuthLayout />}>
        {/* TODO vahvistettu-numeronsiirto claims to use :token, but it doesn't. Why? */}
        <Route path={`vahvistettu-numeronsiirto/:${token}`} element={<AuthorizedNumberPortingPath />} />
        <Route path={`vahvista-numeronsiirto/:${token}`} element={<AuthorizeNumberPortingPath />} />
        <Route path="paluu" element={<AuthenticationReturnPath />} />
        <Route index path={paths.WILDCARD} element={<Navigate replace to={paths.NOT_FOUND_404} />} />
      </Route>
      <Route path="/haku" element={<SearchPublicPagesPath />} />

      <Route path="/työntekijä">
        <Route
          path="kassa"
          element={<Checkout isEmployee={true} />}
          loader={checkoutRootLoader}
          id="checkoutRootEmployee"
        >
          <Route path="kiitos" element={<CheckoutThankYouOrError isEmployee={true} />} />
          <Route index element={<CheckoutSteps isEmployee={true} rootLoaderId="checkoutRootEmployee" />} />
        </Route>

        {/* Employee Email Verification - needs to work for unauthenticated users as well */}
        <Route path={`emailvahvistus/:${token}`} element={<EmailVerificationPath />} />
        <Route element={<Employee />}>
          {/* Employee Home */}
          <Route index element={<EmployeeHomePath />} />

          {/* Employee Devices and Subscriptions */}
          <Route path="puhelinliittymät">
            <Route index element={<Navigate replace to={paths.EMPLOYEE_SUBSCRIPTIONS} />} />
            <Route path={`:${subscriptionId}`}>
              <Route index element={<VoiceSubPath />} />
              <Route path="puheluntiedot" element={<VoiceSubCallDetailsPath />} />
              <Route path="tilaa-sim" element={<VoiceSubOrderSimPath isEmployee={true} />} />
              <Route path="aktivoi-sim-kortti" element={<VoiceSubActivateSimPath isEmployee={true} />} />
            </Route>
            <Route path="lisää">
              <Route index element={<SubAddPath />} />
              <Route path="vahvistuskoodi" element={<EmployeeOneTimePasswordPath />} />
            </Route>
          </Route>

          <Route path="nettiliittymät">
            <Route index element={<Navigate replace to={paths.EMPLOYEE_SUBSCRIPTIONS} />} />
            <Route path={`:${subscriptionId}`} element={<EoeBroadbandSubPath />} />
            <Route path="lisää">
              <Route index element={<SubAddPath />} />
              <Route path="vahvistuskoodi" index element={<EmployeeOneTimePasswordPath />} />
            </Route>
          </Route>

          <Route path="liittymat" element={<SubsPath />} />
          <Route path="ostoskori" element={<ShoppingCartPath />} />

          <Route path="laitteet">
            <Route index element={<EoeDevicesPath />} />
            <Route path={`:${subscriptionId}`}>
              <Route index element={<EoeDevicePath />} />
              <Route path="tee-tukipyynto" element={<EoeDeviceSupportRequestPath />} />
            </Route>
            <Route path="lisää">
              <Route index element={<DeviceAddPath />} />
              <Route path="vahvistuskoodi" element={<EmployeeOneTimePasswordPath />} />
            </Route>
          </Route>

          {/* Employee Device Store */}
          <Route path="kauppa">
            <Route index element={<EmployeeStore />} />
            <Route path="puhelimet">
              <Route index element={<EmployeeStoreProducts category={categories.PHONE} />} />
              <Route path={`:${deviceName}`} element={<EmployeeStoreProduct category={categories.PHONE} />} />
            </Route>

            <Route path="tabletit">
              <Route index element={<EmployeeStoreProducts category={categories.TABLET} />} />
              <Route path={`:${deviceName}`} element={<EmployeeStoreProduct category={categories.TABLET} />} />
            </Route>

            <Route path="tietokoneet">
              <Route index element={<EmployeeStoreProducts category={categories.COMPUTER} />} />
              <Route path={`:${deviceName}`} element={<EmployeeStoreProduct category={categories.COMPUTER} />} />
            </Route>

            <Route path="verkkolaitteet">
              <Route index element={<EmployeeStoreProducts category={categories.NETWORK} />} />
              <Route path={`:${deviceName}`} element={<EmployeeStoreProduct category={categories.NETWORK} />} />
            </Route>

            <Route path="lisatarvikkeet">
              <Route index element={<EmployeeStoreProducts category={categories.ACCESSORY} />} />
              <Route path={`:${deviceName}`} element={<EmployeeStoreProduct category={categories.ACCESSORY} />} />
            </Route>
          </Route>

          {/* Employee Orders */}
          <Route path="tilaukset">
            <Route index element={<Navigate replace to={paths.EMPLOYEE_PENDING_REVIEW_ORDERS} />} />
            <Route path="muutospyynnöt">
              <Route index element={<ChangeRequestsPath />} />
              <Route path={`:${subscriptionId}`} element={<ChangeRequestPath />} />
            </Route>

            <Route path="käsitellyt">
              <Route index element={<FinishedReviewsPath />} />
              <Route path={`:${customerOrderDisplayId}`}>
                <Route index element={<FinishedReviewPath />} />
                <Route path={`:${deliveryId}`} element={<FinishedReviewItemPath />} />
              </Route>
            </Route>

            <Route path="odottaa">
              <Route index element={<PendingReviewsPath />} />
              <Route path={`:${subscriptionId}`}>
                <Route index element={<PendingReviewPath />} />
                <Route path={`:${deliveryId}`} element={<PendingReviewItemPath />} />
              </Route>
            </Route>
          </Route>

          {/* Employee Own Information */}
          <Route path="omat-tiedot" element={<EmployeeOwnInfoPath />} />

          {/* Employee Omalasku */}
          <Route
            path="omalasku"
            id="employeeVoiceSubscriptions"
            element={<Omalasku />}
            errorElement={<RootErrorBoundary />}
          >
            <Route index element={<OmalaskuSelfService />} />
            <Route path={`:${msisdn}`} element={<OmalaskuOrderBarrings />} />
          </Route>

          {/* Employee Own Orders */}
          <Route path="omat">
            <Route index element={<Navigate replace to={paths.EMPLOYEE_OWN_ORDERS} />} />
            <Route path="tilaukset">
              <Route index element={<OwnOrdersPath />} />
              <Route path={`:${subscriptionId}`}>
                <Route index element={<OwnOrderPath />} />
                <Route path={`:${deliveryId}`} element={<OwnOrderItemPath />} />
              </Route>
            </Route>

            <Route path="muutospyynnöt">
              <Route index element={<OwnChangeRequestsPath />} />
              <Route path={`:${subscriptionId}`} element={<OwnChangeRequestPath />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="openform" element={<OpenFormLayout />}>
      <Route index element={<RootErrorBoundary />} />
      <Route path={`list/:${listId}`} element={<OpenFormListLayout />} />
      <Route path={`preview/:${formId}/:${page}?`} element={<OpenFormViewLayout />} />
      <Route path={`view/:${formId}/:${page}?`} element={<OpenFormViewLayout />} />
      <Route path={`view/:${formId}/completed`} element={<OpenFormThankYou />} />
    </Route>
    <Route
      path={paths.WILDCARD}
      element={<PublicPathLayout />}
      errorElement={<RootErrorBoundary />}
      id="public-path-root"
      loader={fetchPublicPageLoader}
    >
      {/* TODO Turbonappi is being ramped down, so this route can be removed once customers are no longer searching for the old service */}
      <Route path="turbonappi" element={<Navigate replace to="/liittymat/lisapalvelut" />} />
      <Route element={<PublicAuthenticationLayout />}>
        <Route element={<PublicHeaderCmsLayout />}>
          <Route
            path="ohjeet"
            element={<InstructionsPath />}
            loader={chatHistoryLoader}
            action={chatConversationAction}
          />
          <Route path={paths.WILDCARD} element={<PublicPath />} />
        </Route>
        <Route element={<PublicHeaderLayout />}>
          <Route path="rekisteroidy" element={<RegistrationPath />} />
          <Route path="yhteydenotto" element={<ContactFormPage />} />
          <Route path="laitenetti" element={<PublicM2MSubscriptions />} loader={publicM2MBroadbandPageLoader} />
          <Route path="mobiililaajakaista" element={<AnonymousMobileBroadbandPath />} />
          <Route path="yritysliittymat" element={<AnonymousMobileVoicePath />} />
        </Route>
        <Route
          path="netin-saatavuus"
          id="fixedBBRoot"
          element={<AnonymousFixedBroadbandOrderPath />}
          loader={fixedBBRootLoader}
        >
          <Route index element={<FixedBroadbandAvailabilityPath />} />
          <Route path="valitse-nettiyhteys" element={<FixedBroadbandOffersPath />} />
          <Route path="tilaa-nettiyhteys" element={<FixedBroadbandCheckoutPath />} loader={fixedBBCheckoutLoader} />
          <Route path="kiitos-tilauksesta" element={<FixedBroadbandThankyouPath />} />
        </Route>
        <Route path="ota-yhteytta" element={<PublicHeaderLayout />}>
          <Route index element={<SupportCasePublicPath />} />
          <Route path="luo-uusi-viesti" element={<NewSupportCasePublic />} />
          <Route path="kiitos-viestista" element={<NewSupportCasePublicThankYou />} />
        </Route>
      </Route>
    </Route>
  </Route>
);

export const siteRoutes = createRoutesFromElements(SitePath());
