import { AdditionalFieldType } from '../../selfservice/common/shopping-cart/shoppingCartEnums.js';
import { AuthenticatedUserRole, IdentityProvider, SimType } from '../../generated/api/models.js';
import { Category, getCommercialProducts, getConfiguredCommercialProducts } from './cartProductUtils.js';
import { DeliveryAddressType } from '../../components/DeliveryAddress/DeliveryAddress.js';
import { DeviceChangeOption, SimCardSelection } from '../enums.js';
import { addEmptyFieldValidationError, convertStringMapToCommonErrors } from './errorUtils.js';
import {
  cartContainsDirectDeliveryItems,
  getOrderItemWithEnrollmentProgram,
} from '../../components/DeviceCheckout/deviceCheckoutUtils.js';
import { fieldCantBeEmptyMsg, t } from '../i18n/index.js';
import { formatContactsToFieldValueOptions } from './contactUtils.js';
import { getDefaultDeliveryMethod } from '../../components/DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import { getEmptyBillingAccount, getReceiverType, prepareBillingAccountSave } from './billingAccountUtils.js';
import { getErrorsFromUpsertPersonBillingAccount } from '../../components/PersonalBillingDetails/PersonalBillingDetails.js';
import {
  initializeOnlineOrderCardPayment,
  setDeliveryDetailsForOnlineOrderCardPayment,
} from '../../selfservice/actions/index.js';
import { paths } from '../constants/pathVariables.js';
import type { Address, BillingAccount, Contact, DeliveryAddress, DeliveryMethod } from '../../generated/api/models.js';
import type { AuthenticatedUserState, CompanyInfoState, OnlineModelsState } from '../types/states.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../types/errors.js';
import type { ConfiguredOffer } from '../types/commercialProduct.js';
import type { DeviceChangeRequest } from '../types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../components/DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { Dispatch } from 'redux';
import type {
  FormattedContactOption,
  Price,
  ShoppingCartItemForCheckout,
  SubmitOrderProps,
} from '../types/checkout.js';
import type { SimCardConfiguration } from '../types/subscription.js';

// common functions

export const getDefaultAddressType = (): DeliveryAddressType => DeliveryAddressType.COMPANY_ADDRESS;

export const PriceSubType = {
  EPP_DEVICE: 'EPP_DEVICE',
};
export const SubscriptionDetailForDeviceChangeKey = 'subscription-detail-for-device-change' as const;
export const isEppDevicePriceSubType = (price: Price): boolean =>
  price.periodic?.priceSubType === PriceSubType.EPP_DEVICE;

export const isEppDevicePresentInCart = (cartItems: Array<ShoppingCartItemForCheckout>): boolean =>
  cartItems ? cartItems.some((product: ShoppingCartItemForCheckout) => isEppDevicePriceSubType(product.price)) : false;

export const EPP_PRODUCT_TYPE = 'EPP';
export const DIRECT_DELIVERY_PRODUCT_TYPE = 'DIRECTDELIVERY';
export const VAK_PRODUCT_TYPE = 'VAK';
export const PRODUCT_DELIVERY_TYPE = 'ProductDeliveryType';

export const getProductDeliveryTypesInCart = (
  cartItems: Array<ShoppingCartItemForCheckout>,
  onlineModels?: OnlineModelsState
): string[] => {
  const productDeliveryTypes = cartItems
    .map(cartItem => {
      const onlineModel = onlineModels?.items?.find(model => model.onlineModelCode === cartItem.onlineModelCode);
      const offer = onlineModel?.offers.find(o => o.offerCode === cartItem.offerCode);

      return offer?.properties?.find(prop => prop.name === PRODUCT_DELIVERY_TYPE)?.value;
    })
    .filter(item => item !== undefined);

  if (isEppDevicePresentInCart(cartItems)) {
    productDeliveryTypes.push(EPP_PRODUCT_TYPE);
  }

  if (cartContainsDirectDeliveryItems(cartItems, onlineModels)) {
    productDeliveryTypes.push(DIRECT_DELIVERY_PRODUCT_TYPE);
  }

  return productDeliveryTypes;
};

export const showEppDeviceChangeStep = (
  cartItems: Array<ShoppingCartItemForCheckout>,
  user?: AuthenticatedUserState
): boolean => {
  return Boolean(
    isEppDevicePresentInCart(cartItems) && user?.expiringEppSubscriptions && user.expiringEppSubscriptions.length
  );
};

export const getFormattedContacts = (
  contacts: Contact[] = [],
  user?: AuthenticatedUserState
):
  | {
      displayOptions: string[];
      options: FormattedContactOption[];
    }
  | undefined => {
  const filteredContacts = contacts.filter(item => item.contactId !== user?.contact?.contactId);
  if (filteredContacts) {
    return formatContactsToFieldValueOptions(filteredContacts, false);
  }
  return undefined;
};

export const getDeliveryCharges = (
  deliveryDetails: DeviceCheckoutDeliveryDetailsType | undefined,
  skipDelivery = false,
  listOfDeliveryMethods?: DeliveryMethod[]
): number => {
  if (skipDelivery) {
    return 0;
  }
  return (
    deliveryDetails?.deliveryPrice ||
    (listOfDeliveryMethods ? getDefaultDeliveryMethod(listOfDeliveryMethods)?.price : 0)
  );
};

export const isHideDeliveryMethodSelection = (cartItems: Array<ShoppingCartItemForCheckout> = []): boolean =>
  Boolean(
    cartItems.length > 0 &&
      cartItems.map(cartItem => cartItem.category === Category.SALES_PRODUCT).reduce((prev, current) => prev && current)
  );

const isSimConfigurationHasNewPhysicalSim = (simConfiguration: SimCardConfiguration): boolean =>
  // TODO: leo 13.4.2022 - this seems to be only needed for public site orders. Currently public site Laitenetti orders
  //  go via OEC, if Laitenetti ordering from public site will be changed to be handled by online-ui, then this will need changes.
  //  Same thing in onlineOrderEpicUtils.ts.
  Boolean(
    simConfiguration?.simType === SimType.PHYSICAL && simConfiguration?.simSelection === SimCardSelection.ORDER_NEW
  );

const isSimConfigurationsHaveAnyDeliveryItem = (simConfigurations: (SimCardConfiguration | undefined)[]): boolean =>
  Boolean(
    simConfigurations?.length > 0 &&
      simConfigurations
        .filter(simConfiguration => simConfiguration !== undefined)
        .map(simConfiguration => isSimConfigurationHasNewPhysicalSim(simConfiguration!))
        .reduce((prev, current) => prev || current)
  );

export const showDeliveryStep = (cartItems: Array<ShoppingCartItemForCheckout> = []): boolean =>
  Boolean(
    !isHideDeliveryMethodSelection(cartItems) ||
      cartItems
        .map(cartItem => isSimConfigurationsHaveAnyDeliveryItem(cartItem.simCardConfigurations))
        .reduce((prev, current) => prev && current)
  );

export const isConfigurableSimCardItem = (item: ShoppingCartItemForCheckout) =>
  item.category === Category.SALES_PRODUCT &&
  item.simCardConfigurations.length === 0 &&
  item.additionalFields.some(field =>
    [
      AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_EXISTING_PHONE_NUMBER,
      AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_NEW_PHONE_NUMBER,
      AdditionalFieldType.NUMBER_AND_NUMBER_PRIVACY_AND_SIM_CARD_SELECTION,
      AdditionalFieldType.SIM_CARD_SELECTION,
    ].includes(field.type)
  );

export const hasConfigurableSimCardItems = (cartItems: Array<ShoppingCartItemForCheckout>) =>
  cartItems.some(isConfigurableSimCardItem);

export const getErrorsFromCheckout = (
  approverContact?: string,
  personBillingAddress?: Address,
  personBillingEmail?: string,
  validationErrors?: CommonError[]
): CommonError[] | undefined => {
  const errors: CommonError[] = [];
  if (approverContact !== undefined && approverContact.length === 0) {
    addEmptyFieldValidationError(errors, 'approverContact', t.VPVR(fieldCantBeEmptyMsg));
  }
  validationErrors = getErrorsFromUpsertPersonBillingAccount(
    personBillingAddress,
    personBillingEmail,
    validationErrors
  );
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
};

export const hasEmployeeContribution = (cartItems: Array<ShoppingCartItemForCheckout>, isEmployee: boolean) => {
  if (!cartItems) {
    return undefined;
  }
  return (
    isEmployee &&
    getConfiguredCommercialProducts(cartItems).some(item => (item.commercialProduct.monthlyRecurringCharge || 0) > 0)
  );
};

export const hasEmployeePayables = (
  cartItems: Array<ShoppingCartItemForCheckout>,
  isEmployee: boolean,
  deviceChangeRequest?: DeviceChangeRequest
) => Boolean(hasEmployeeContribution(cartItems, isEmployee) || deviceChangeRequest?.deviceChangeRedeemPrice);

const createOrderItemOffer = (item: ShoppingCartItemForCheckout) => ({
  commercialProducts: [],
  created: 1,
  lastModified: 1,
  offerCode: item.offerCode,
  offerId: '',
  offerName: '',
});

export const getOrderItems = (
  cartItems: Array<ShoppingCartItemForCheckout> = [],
  deviceChangeRequest?: DeviceChangeRequest
): ConfiguredOffer[] => {
  const commercialProducts = getCommercialProducts(cartItems);
  const orderItems: ConfiguredOffer[] = cartItems.map((cartItem, index) => ({
    // since device Offer isn't needed to place order, hence all data in orderItem offer below is vague,
    // except offerCode (only thing needed to place online order)
    // just to match the input type to the submitOrder redux action.
    key: index,
    offer: createOrderItemOffer(cartItem),
    onlineModelCode: cartItem.onlineModelCode,
    onlineModelName: cartItem.onlineModelName,
    selectedCommercialProducts: commercialProducts[index],
  }));

  if (deviceChangeRequest?.deviceChangeOption === DeviceChangeOption.RETURN) {
    let replacementDevice: ConfiguredOffer | undefined;
    orderItems.forEach(item => {
      if (
        item.selectedCommercialProducts[0].commercialProduct.commercialProductCode ===
        deviceChangeRequest?.replacementDeviceCommercialProductCode
      ) {
        if (item.selectedCommercialProducts.length > 1) {
          // if more than one quantity then there could be different POU contact (When admin orders product) hence append replacedSubscriptionId for matching contactId
          const replacementDeviceIndex = item.selectedCommercialProducts.findIndex(
            selectedCommercialProduct =>
              selectedCommercialProduct.purposeOfUseOrContact.contactId ===
              deviceChangeRequest?.replacedSubscriptionContactId
          );
          replacementDevice = {
            ...item,
            selectedCommercialProducts: [
              {
                ...item.selectedCommercialProducts[replacementDeviceIndex],
                replacedSubscriptionId: deviceChangeRequest.replacedSubscriptionId,
              },
            ],
          };
          item.selectedCommercialProducts.splice(replacementDeviceIndex, 1);
        } else {
          item.selectedCommercialProducts[0].replacedSubscriptionId = deviceChangeRequest.replacedSubscriptionId;
        }
      }
    });
    if (replacementDevice) {
      orderItems.unshift(replacementDevice);
    }
  }
  // Enrollment Program Selected Implementation
  return getOrderItemWithEnrollmentProgram(orderItems);
};

export const getCompanyName = (
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType,
  user?: AuthenticatedUserState,
  companyName?: string
): string =>
  deliveryDetails?.addressType === DeliveryAddressType.HOME_ADDRESS
    ? ''
    : (companyName ?? deliveryDetails?.companyName ?? user?.companyName ?? '');

export const getDeliveryDetails = (
  submitOrderProps: SubmitOrderProps,
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType,
  user?: AuthenticatedUserState
): DeliveryAddress => ({
  companyName: getCompanyName(deliveryDetails, user, submitOrderProps?.updatedDeliveryDetails?.companyName),
  recipient: submitOrderProps?.updatedDeliveryDetails?.recipientName ?? deliveryDetails?.recipientName ?? '',
  phoneNumber:
    submitOrderProps?.updatedDeliveryDetails?.recipientPhoneNumber ?? deliveryDetails?.recipientPhoneNumber ?? '',
  address: submitOrderProps?.updatedDeliveryDetails?.address ?? deliveryDetails?.address ?? ({} as Address),
  pickupPoint: submitOrderProps?.updatedDeliveryDetails?.pickupPoint ?? deliveryDetails?.pickupPoint,
});

export const submitOrderWithCardPayment = (
  submitOrderProps: SubmitOrderProps,
  orderItems: ConfiguredOffer[],
  dispatch: Dispatch,
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType,
  user?: AuthenticatedUserState
) => {
  if (submitOrderProps.updatedDeliveryDetails) {
    dispatch(setDeliveryDetailsForOnlineOrderCardPayment(submitOrderProps.updatedDeliveryDetails));
  } else if (deliveryDetails) {
    dispatch(setDeliveryDetailsForOnlineOrderCardPayment(deliveryDetails));
  }
  dispatch(
    initializeOnlineOrderCardPayment(
      orderItems,
      getDeliveryDetails(submitOrderProps, deliveryDetails, user),
      submitOrderProps?.updatedDeliveryDetails?.deliveryMethodType ?? deliveryDetails!.deliveryMethodType
    )
  );
};

export const extractValues = (
  valuesOrErrorSupplier?: BillingAccountOrErrorSupplier
): [object | undefined, CommonError[] | undefined] => {
  if (valuesOrErrorSupplier) {
    const { obj, validationErrors } = valuesOrErrorSupplier();
    return [obj, convertStringMapToCommonErrors(validationErrors)];
  }
  return [undefined, undefined];
};

export const extractNewBillingAccountDetails = (
  newBillingAccountSupplier?: BillingAccountOrErrorSupplier,
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType,
  user?: AuthenticatedUserState
): {
  newBillingAccount?: BillingAccount;
  newBillingAccountCommonFunction?: Contact;
  newBillingAccountValidationErrors?: CommonError[];
} => {
  const [newBillingAccountValuesObj, newBillingAccountValidationErrors] = extractValues(newBillingAccountSupplier);
  let updatedBillingAccount: BillingAccount | undefined;
  if (newBillingAccountValuesObj) {
    const values = newBillingAccountValuesObj as BillingAccount;
    const receiverType = getReceiverType(values);
    updatedBillingAccount = prepareBillingAccountSave(
      getEmptyBillingAccount(getCompanyName(deliveryDetails, user)),
      values,
      receiverType
    );
  }
  return {
    newBillingAccount: updatedBillingAccount,
    newBillingAccountValidationErrors: newBillingAccountValidationErrors,
  };
};

export const isCartEmpty = (cart: Array<ShoppingCartItemForCheckout> = []) => {
  return cart.length === 0;
};

export const isNotHavePermissionToBuy = (isEmployee: boolean, loggedIn?: boolean, user?: AuthenticatedUserState) => {
  const isElisaIdUser =
    user?.identityProvider &&
    [IdentityProvider.ELISA_ID, IdentityProvider.ELISA_ID_V2].includes(user?.identityProvider);
  const isKeyUser = user?.userRole === AuthenticatedUserRole.KEY_USER;
  return loggedIn && (!user?.businessId || (isEmployee && !isElisaIdUser) || (!isEmployee && !isKeyUser));
};

export const getSubscriptionDetailForDeviceChangeFromSessionStorage = ():
  | {
      contactId?: string;
      subscriptionId?: string;
    }
  | undefined => {
  const subscriptionDetailForDeviceChange = sessionStorage.getItem(SubscriptionDetailForDeviceChangeKey) || undefined;
  return subscriptionDetailForDeviceChange ? JSON.parse(subscriptionDetailForDeviceChange) : undefined;
};

// Returns true if on public checkout or EOE thank you page, as well as the card payment pages (both IN_PROGRESS and COMPLETE).
export const isFinalPage = (pathname: string) =>
  pathname.endsWith(paths.DEVICE_CHECKOUT_THANK_YOU) || pathname.startsWith(paths.DEVICE_CHECKOUT_CARD_PAYMENT);

export const getSelectedDeviceChangeTitleSuffix = (deviceChangeRequest?: DeviceChangeRequest): string => {
  if (deviceChangeRequest) {
    switch (deviceChangeRequest.deviceChangeOption) {
      case DeviceChangeOption.REDEEM:
        return t.YTNE(DeviceChangeOption.REDEEM);
      case DeviceChangeOption.RETURN:
        return t.OMNZ(DeviceChangeOption.RETURN);
      case DeviceChangeOption.NONE:
        return '';
    }
  } else {
    return '';
  }
};

export const getDeliveryDetailsObj = (
  listOfDeliveryMethods?: DeliveryMethod[],
  companyInfo?: CompanyInfoState,
  user?: AuthenticatedUserState,
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType
): DeviceCheckoutDeliveryDetailsType => ({
  address:
    deliveryDetails && deliveryDetails.address
      ? deliveryDetails.address
      : companyInfo
        ? companyInfo.address
        : undefined,
  addressType: (deliveryDetails && deliveryDetails.addressType) || getDefaultAddressType(),
  deliveryMethodDescription:
    deliveryDetails?.deliveryMethodDescription || getDefaultDeliveryMethod(listOfDeliveryMethods).createDescription(),
  deliveryMethodType: deliveryDetails?.deliveryMethodType || getDefaultDeliveryMethod(listOfDeliveryMethods).type,
  deliveryPrice: deliveryDetails?.deliveryPrice || getDefaultDeliveryMethod(listOfDeliveryMethods).price,
  recipientName:
    deliveryDetails?.recipientName || (user ? user.firstName + (user.lastName ? ' ' + user.lastName : '') : ''),
  recipientPhoneNumber: deliveryDetails?.recipientPhoneNumber || user?.mobile || '',
  pickupPoint: deliveryDetails?.pickupPoint,
  companyName: deliveryDetails?.companyName || user?.companyName,
  shipmentType: deliveryDetails?.shipmentType,
});
